import styles from './Input.module.css';

export default function Input({ style, title, placeholder, value, onChange }) {
	return (
		<div style={style}>
			<div className={styles.title}>{title}</div>
			<div className={styles.inputParent}>
				<input className={styles.input} type={'text'} placeholder={placeholder} spellCheck={false} autoComplete={'off'} value={value} onChange={(e) => onChange(e.target.value)}/>
			</div>
		</div>
	);
}
