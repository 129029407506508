import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Config from '../../config';

import styles from './InvoicesPage.module.css';

import Logo from '../../components/Logo/Logo';
import Selector from '../../components/Selector/Selector';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

export default function InvoicesPage() {
	const params = useParams();
	const invoiceId = params.invoiceId;
	const token = params.token;

	const [invoice, setInvoice] = useState(null);
	const [globalError, setGlobalError] = useState(null);
	const [vat, setVat] = useState(true);
	const [informationCompleted, setInformationCompleted] = useState(true);
	const [localDataUpdatedAt, setLocalDataUpdatedAt] = useState(new Date());
	const [inlineError, setInlineError] = useState(null);

	const [typeValue, setTypeValue] = useState(null);
	const [locationValue, setLocationValue] = useState(null);
	const [intraCommunitaryValue, setIntraCommunitaryValue] = useState(null);
	const [userInput, setUserInput] = useState({});

	const [buttonLoading, setButtonLoading] = useState(false);

	// Load invoice information
	useEffect(() => {
		getInvoiceInformation(invoiceId, token);
	}, [invoiceId, token]);

	// Make tax logics
	useEffect(() => {
		// Individuals
		if (typeValue === 'individual') {
			if (locationValue === 'eu') return setVat(true);
			if (locationValue === 'non-eu') return setVat(false);
		}

		// Companies
		if (typeValue === 'company') {
			if (locationValue === 'non-eu') return setVat(false);
			if (locationValue === 'eu') {
				if (intraCommunitaryValue === 'intracommunitary') return setVat(false);
				if (intraCommunitaryValue === 'non-intracommunitary') return setVat(true);
			}
		}
	}, [typeValue, locationValue, intraCommunitaryValue, setVat]);

	// Is information complete?
	const userInputs = [userInput.name, userInput.email, userInput.phone, userInput.address_line1, userInput.address_city, userInput.address_postalCode, userInput.address_country];

	useEffect(() => {
		const informationCompleted = isInformationCompleted();

		return setInformationCompleted(informationCompleted);
	}, [typeValue, locationValue, intraCommunitaryValue, localDataUpdatedAt, isInformationCompleted]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	function isInformationCompleted() {
		if (!typeValue || !locationValue) return false;

		if (typeValue === 'company' && locationValue === 'eu' && !intraCommunitaryValue) {
			return false;
		}

		if (typeValue === 'company' && !userInput.taxNumber) return false;

		if (!userInput) return false;

		for (let k of userInputs) {
			if (!k) return false;
		}

		return true;
	}

	async function getInvoiceInformation(invoiceId, token) {
		let response;

		try {
			response = await axios.get(`${Config.apiEndpoint}/pay/invoice/${invoiceId}/${token}`);
		} catch (e) {
			return setGlobalError((e.response && e.response.data.error.message) || e.message); 
		}

		if (!response || !response.data || !response.data.data) {
			return setGlobalError('Could not get invoice'); 
		}

		const invoiceData = response.data.data;

		if (invoiceData.stripe && invoiceData.stripe.hostedInvoiceUrl) {
			return window.location.href = invoiceData.stripe.hostedInvoiceUrl;
		}

		setInvoice(invoiceData);
		
		if (invoiceData.description) {
			document.title = 'Invoice - ' + invoiceData.description + ' - LastBasic';
		}

		return;
	}

	async function sendUserInformation() {
		setButtonLoading(true);
		setInlineError(null);

		let response;

		try {
			response = await axios.put(`${Config.apiEndpoint}/pay/invoice/${invoiceId}/${token}`, {
				name: userInput.name,
				type: typeValue,
				email: userInput.email,
				phone: userInput.phone,
				european: (locationValue === 'eu'),
				taxNumber: userInput.taxNumber || '',
				euIntraCommunity: (intraCommunitaryValue === 'intracommunitary'),
				address: {
					line1: userInput.address_line1,
					city: userInput.address_city,
					postalCode: userInput.address_postalCode,
					country: userInput.address_country,
				},
				coupon: userInput.coupon || '',
			});
		} catch (e) {
			setButtonLoading(false);

			const error = (e.response && e.response.data.error.message) || e.message;
			return setInlineError(error);
		}

		const invoiceData = response.data.data;

		if (invoiceData.stripe && invoiceData.stripe.hostedInvoiceUrl) {
			return window.location.href = invoiceData.stripe.hostedInvoiceUrl;
		}

		setButtonLoading(false);
		return;
	}

	function updateUserInput(key, value) {
		const newInput = userInput;
		newInput[key] = value;

		setLocalDataUpdatedAt(new Date());
		return setUserInput(newInput);
	}

	if (globalError) {
		return (
			<div className={styles.wrapper}>
				<center>
					<Logo size={'medium'} />
				</center>
	
				<div className={styles.whiteBox}>
					<div className={styles.box + ' ' + styles.globalError}>
						{globalError}
					</div>
				</div>
			</div>
		);
	}

	if (!invoice) {
		return (
			<div className={styles.wrapper}>
				<center>
					<Logo size={'medium'} />
				</center>
	
				<div className={styles.whiteBox}>
					<div className={styles.box} style={{ textAlign: 'center' }}>
						<div className={styles.skeletonContent} style={{ display: 'inline-block', width: '200px', height: '20px' }}></div>
						<br/>
						<div className={styles.skeletonContent} style={{ display: 'inline-block', width: '120px', height: '40px', marignTop: '20px' }}></div>
					</div>
				</div>
			</div>
		);
	}

	// Backwards currency compatibility - TBD - Remove when API currency changes merged
	if (invoice && !invoice.currency) {
		invoice.currency = 'USD';
	}

	return (
		<div className={styles.wrapper}>
			<center>
				<Logo size={'medium'} />
			</center>

			<div className={styles.whiteBox}>
				<div className={styles.box}>
					<div className={styles.description}>{invoice.description}</div>
					<div className={styles.price}>
						<div className={styles.priceNumber}>
							{new Intl.NumberFormat('en-EN', { maximumFractionDigits: 2 }).format(invoice.amountInCents / 100)}
							<div className={styles.currencyValue}>{invoice.currency}</div>
						</div>

						<div className={styles.priceDetails}>
							{new Intl.NumberFormat('en-EN', { maximumFractionDigits: 2 }).format((invoice.amountInCents / 100) * (vat ? 1.21 : 1))} {invoice.currency} ({(vat ? 21 : 0)}% VAT included)
						</div>
					</div>
				</div>
				<div className={styles.infoBox}>
					ⓘ&nbsp;&nbsp;We need more information to create the perfect invoice for you. After filling this small form you will be redirected to a payment gateway, and your project
					will start taking shape with us.
				</div>

				<div className={styles.form}>
					<Selector
						style={{ marginTop: '30px'}}
						title={'About you'}
						selected={typeValue}
						onChange={(o) => setTypeValue(o)}
						options={[
							{
								title: 'I\'m a company',
								value: 'company'
							},
							{
								title: 'I\'m an individual',
								value: 'individual'
							},
						]}
					/>

					{
						typeValue &&
						<>
						<Selector
							style={{ marginTop: '30px'}}
							title={'Based in'}
							selected={locationValue}
							onChange={(o) => setLocationValue(o)}
							options={[
								{
									title: 'In the EU',
									value: 'eu'
								},
								{
									title: 'Outside the EU',
									value: 'non-eu'
								},
							]}
						/>
						</>	
					}

					{
						typeValue && typeValue === 'company' && locationValue === 'eu' &&
						<>
						<Selector
							style={{ marginTop: '30px'}}
							title={'Do you have an intra-communitary VAT number'}
							selected={intraCommunitaryValue}
							onChange={(o) => setIntraCommunitaryValue(o)}
							options={[
								{
									title: 'I do',
									value: 'intracommunitary'
								},
								{
									title: 'I don\'t',
									value: 'non-intracommunitary'
								},
							]}
						/>
						</>	
					}
					
					{
						typeValue === 'company' && locationValue &&
						<>
						<Input
							style={{ marginTop: '30px'}}
							title={'Business information'}
							placeholder={'Business name'}
							value={userInput.name}
							onChange={(t) => updateUserInput('name', t)}
						/>

						<Input
							style={{ marginTop: '10px'}}
							placeholder={((locationValue === 'eu' && intraCommunitaryValue === 'intracommunitary') ? 'Intra-communitary VAT number (CC + VAT number)'  : 'VAT number')}
							value={userInput.taxNumber}
							onChange={(t) => updateUserInput('taxNumber', t)}
						/>
						</>
					}

					{
						typeValue && locationValue &&
						<>
						{
							typeValue !== 'company' &&
							<Input
								style={{ marginTop: '30px'}}
								title={'Billing information'}
								placeholder={'Name'}
								value={userInput.name}
								onChange={(t) => updateUserInput('name', t)}
							/>
						}

						<Input
							title={(typeValue === 'company' && 'Billing information')}
							style={{ marginTop: ((typeValue !== 'company' && '10px') || '30px')}}
							placeholder={'Email address'}
							value={userInput.email}
							onChange={(t) => updateUserInput('email', t)}
						/>

						<Input
							style={{ marginTop: '10px'}}
							placeholder={'Phone number'}
							value={userInput.phone}
							onChange={(t) => updateUserInput('phone', t)}
						/>
						</>
					}
					
					{
						typeValue && locationValue &&
						<>
						<Input
							style={{ marginTop: '30px'}}
							title={'Billing address'}
							placeholder={'Address line'}
							value={userInput.address_line1}
							onChange={(t) => updateUserInput('address_line1', t)}
						/>

						<Input
							style={{ marginTop: '10px'}}
							placeholder={'City'}
							value={userInput.address_city}
							onChange={(t) => updateUserInput('address_city', t)}
						/>

						<Input
							style={{ marginTop: '10px'}}
							placeholder={'ZIP code'}
							value={userInput.address_postalCode}
							onChange={(t) => updateUserInput('address_postalCode', t)}
						/>

						<Input
							style={{ marginTop: '10px'}}
							placeholder={'Country'}
							value={userInput.address_country}
							onChange={(t) => updateUserInput('address_country', t)}
						/>

						{
							typeValue === 'individual' && invoice.amountInCents > 0 &&
							<>
							<Input
								style={{ marginTop: '10px'}}
								placeholder={'Your ID number (government ID, passport, driving license...)'}
								value={userInput.taxNumber}
								onChange={(t) => updateUserInput('taxNumber', t)}
							/>
							</>
						}

						<Input
							style={{ marginTop: '30px'}}
							title={'Discount coupon (optional)'}
							placeholder={'Coupon'}
							value={userInput.coupon}
							onChange={(t) => updateUserInput('coupon', t)}
						/>

						{ 
							inlineError && <div className={styles.inlineError}>{inlineError}</div>
						}

						<Button
							style={{ marginTop: '30px'}}
							title={(buttonLoading ? 'Creating invoice...' : 'Continue to payment')}
							disabled={!informationCompleted}
							loading={buttonLoading}
							onClick={sendUserInformation}
						/>
						</>
					}
				</div>
			</div>

			<div className={styles.footer}>
				We will redirect you to Stripe to make the payment. After it, your project will start with us in the platform.
				Check the <a target={'_blank'} rel={'noreferrer'} href={'/files/project-setup/lastbasic-terms-conditions-project-setup.pdf'}>Terms and conditions</a>
			</div>
		</div>
	);
}
