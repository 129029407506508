import styles from './Button.module.css';

export default function Button({ title, style, disabled, loading, onClick }) {
	if (loading) {
		return (
			<div style={style} className={styles.button + ' ' + styles.loading}>{title}</div>
		);
	}

	return (
		<div style={style} onClick={onClick} className={styles.button + ' ' + (disabled && styles.disabled)}>{title}</div>
	);
}
