const configuration = {
	'local': {
		environment: 'local',
		apiEndpoint: 'http://localhost:8022',
		websiteUrl: 'https://local.lastbasic.com',
	},
	'dev': {
		environment: 'dev',
		apiEndpoint: 'https://dev.api.lastbasic.com',
		websiteUrl: 'https://dev.lastbasic.com',
	},
	'preprod': {
		environment: 'preprod',
		apiEndpoint: 'https://preprod.api.lastbasic.com',
		websiteUrl: 'https://preprod.lastbasic.com',
	},
	'prod': {
		environment: 'prod',
		apiEndpoint: 'https://api.lastbasic.com',
		websiteUrl: 'https://lastbasic.com',
	},
};

function config() {
	const host = window.location.host; 
	
	if (host.indexOf('localhost') !== -1) return configuration['local'];
	if (host.indexOf('dev.pay.lastbasic.com') !== -1) return configuration['dev'];
	if (host.indexOf('preprod.pay.lastbasic.com') !== -1) return configuration['preprod'];
	if (host.indexOf('pay.lastbasic.com') !== -1) return configuration['prod'];

	return configuration['local'];
}

export default config();
