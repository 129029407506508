import styles from './Logo.module.css';

export default function Logo({ size, linkTo }) {
	const sizeClass = styles['size_' + (size || 'small')];

	if (linkTo) {
		return (
			<div className={sizeClass}>
				<a href={linkTo}>
					<div className={styles.logo + ' ' + sizeClass}></div>
				</a>
			</div>
		);
	}

	return (
		<div className={styles.logo + ' ' + sizeClass}></div>
	);
}
