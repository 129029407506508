import styles from './Selector.module.css';

export default function Selector({ title, options, selected, onChange, style }) {
	return (
		<div style={style}>
			<div className={styles.title}>{title}</div>
			<div className={styles.selectorParent}>
				{
					options.map(o => {
						return (
							<>
								<div
									className={styles.selector + ' ' + ((selected === o.value && styles.selected) || (!selected ? styles.noneSelected : styles.notSelected))}
									onClick={() => onChange(o.value)}
								>
									{o.title}
								</div>
							</>
						);
					})
				}
			</div>
		</div>
	);
}
