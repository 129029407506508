import { BrowserRouter, Routes, Route } from 'react-router-dom';

import InvoicesPage from './pages/InvoicesPage/InvoicesPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';

export default  function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path={'/invoices/:invoiceId/:token'} element={<InvoicesPage />} />
				<Route path={'*'} element={<NotFoundPage />} />
			</Routes>
		</BrowserRouter>
	);
}
